import React, { useContext, useEffect, useState, useRef } from 'react';
import { PageIdContext } from '../../../../../contexts/SiteSettingsProvider';
import styles from './SearchForm.module.css';
import Guests from '../../../../SearchForm/Guests/Guests';
import DatePicker from '../../../../SearchForm/DatePicker/DatePicker';
import Filters from '../../../../SearchForm/Filters/Filters';
import Location from '../../../../SearchForm/Location/Location';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const SearchForm = ({
    view = "horizontal",
    searchFormSettings,
    isLoading,
    //guests
    changeAdults,
    changeChildren,
    changeInfants,
    //dates  
    transferDates,
    transferFlexibility,
    //filters
    transferPriceMode,
    transferAttrsOnLoad,
    transferAttrsOnChange,
    startSearch,
    transWeekRange,

    //income  attributes
    priceRange,
    priceRangeSliderSettings,
    transNightRange,
    attributes,
    enablePriceRangeSlider,
    isFilters,

    //location
    transferLocation,
    clearChildFiled,
    locationsIds,
    propertyAttributesIds,
    limitLocations
}) => {


    const pageID = useContext(PageIdContext);
    const enableSearchForm = (searchFormSettings.EnableLocationFilter || searchFormSettings.EnableDateFilter || searchFormSettings.EnableGuestFilter || searchFormSettings.EnableAttributesFilter) || searchFormSettings.EnableLocationFilterDisableSearch;
    const [formHeight, setFormHeight] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const searchFormRef = useRef(null);

    useEffect(() => {
       

        const handleScroll = () => {
            // Check if the ref is attached and element is in the DOM
            if (searchFormRef.current) {
                const rect = searchFormRef.current.getBoundingClientRect();
                const sticky = window.pageYOffset >= rect.top + window.pageYOffset + 100;
                setIsSticky(sticky);
                console.log('scrolling', window.pageYOffset, rect.top + window.pageYOffset);  // Debugging line
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [enableSearchForm]);

    useEffect(() => {
        const formHeight = document.querySelector('.searchFormWrapper')?.offsetHeight;
        setFormHeight(formHeight);

    }, [isSticky]);

    return (
        <>
            {enableSearchForm &&
                <div ref={searchFormRef} className={`${styles.searchForm} searchFormWrapper`} style={{ height: formHeight }}>
                    <div className="loading tw-relative tw-top-0 tw-bg-white search-from-wrapper">
                        <div className={`${view} search-from tw-relative ${isSticky ? '!tw-fixed tw-left-0 tw-w-full tw-top-0 tw-bg-white tw-px-5' : 'tw-relative'}`}>

                            {searchFormSettings.EnableLocationFilter &&
                                <div className="serch-form-field tw-bg-white location">
                                    <Location
                                        pageID={pageID}
                                        locationList={searchFormSettings.Locations}
                                        enableLocationFilter={searchFormSettings.EnableLocationFilter}

                                        clearField={clearChildFiled}
                                        changeLocation={transferLocation}

                                        settings={searchFormSettings}
                                        locationsIds={locationsIds}
                                        limitLocations={false}
                                    />
                                </div>
                            }


                            {searchFormSettings.EnableDateFilter &&
                                <div className="serch-form-field tw-bg-white dates">
                                    <DatePicker
                                        changeDates={transferDates}
                                        onChangeFlexibility={transferFlexibility}
                                        enableFlexibleNightsFilter={true}
                                        enableDateFilter={true}
                                    />
                                </div>
                            }


                            {searchFormSettings.EnableGuestFilter &&
                                <div className="serch-form-field tw-bg-white guests">
                                    <Guests
                                        changeAdults={changeAdults}
                                        changeChildren={changeChildren}
                                        changeInfants={changeInfants}
                                        enableGuestFilter={true}
                                    />
                                </div>
                            }


                            {searchFormSettings.EnableAttributesFilter &&
                                <div className="serch-form-field tw-bg-white filters">
                                    <Filters
                                        changePriceMode={transferPriceMode}
                                        changeAttrsOnLoad={transferAttrsOnLoad}
                                        changeAttrsOnValueChange={transferAttrsOnChange}
                                        startSearchFromModal={startSearch}

                                        changeNightRange={transNightRange}
                                        changeWeekRange={transWeekRange}

                                        priceRangeSliderSettings={priceRangeSliderSettings}
                                        attributesList={attributes}

                                        enablePriceRangeSlider={enablePriceRangeSlider}
                                        enabledFilters={isFilters}
                                        propertyAttributesIds={propertyAttributesIds}
                                    />
                                </div>
                            }

                            <div className="serch-form-field tw-bg-white search-btn">
                                <button className="search-form-btn field tw-bg-primary tw-text-white tw-min-w-[120px]" onClick={() => { if (!isLoading) { startSearch(); } }} disabled={isLoading}>

                                    <span >Search</span>
                                    {isLoading ?
                                        <Spinner animation="grow" size="sm" className="tw-ml-4 tw-relative tw-top-[2px] " variant="white" >
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                            style={{ color: '#fff' }}
                                            size='lg'
                                            className='tw-ml-4 tw-relative tw-top-[2px] '
                                        />

                                    }
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            }
        </>
    );
};

export default SearchForm;
